.key-detail-container {
  .key-detail-header {
    margin-top: 2rem;
  }

  .key-jobs-container {
    margin-top: .6rem;

    .key-jobs-empty {
      text-align: center;
      font-size: .9rem;
      color: #777;

      >svg {
        font-size: 6rem;
        color: #ddd;
      }
    }

    .key-jobs-card {
      font-size: .9rem;

      >hr {
        margin-top: 1.5rem;
        border-color: #eee;
      }

      .key-jobs-options {
        margin-top: .4rem;
        text-align: center;

        >button {
          padding: .3rem .6rem;
          font-size: .8rem;
          line-height: .8rem;
        }
      }

      .list-helper-text {
        margin-bottom: .2rem;
        font-size: .6rem;
        color: #777;
      }

      .symbol-icon {
        width: 1.2rem;
        margin-right: .3rem;
        vertical-align: sub;
      }
    }
  }

  .key-detail-pagination-container {
    margin: 1.2rem 0;
  }
}
