.handle-key-container {
  .handle-key-header {
    margin-top: 1.5rem;

    .exchange-name {
      flex-grow: 1;
      font-size: 1rem;
    }
  }

  .key-form-container {
    .form-holder {
      margin-top: 2rem;
    }

    .btn-submit {
      margin-top: 1.5rem;
    }

    .btn-cancel {
      margin-top: 1.2rem;
    }
  }

  .keys-container {
    margin-top: .6rem;

    .keys-empty {
      text-align: center;
      font-size: .9rem;
      color: #777;

      >svg {
        font-size: 6rem;
        color: #ddd;
      }
    }

    .keys-card {
      font-size: .9rem;

      >hr {
        margin-top: 1.5rem;
        border-color: #eee;
      }

      .keys-options {
        margin-top: .5rem;
        text-align: center;

        >button {
          margin-bottom: .5rem;
          padding: .3rem .6rem;
          font-size: .8rem;
          line-height: .8rem;
        }
      }

      .keys-helper-text {
        margin-bottom: .1rem;
        font-size: .6rem;
        color: #777;
      }
    }

  }

  .handle-key-pagination-container {
    margin: 1.2rem 0;
  }
}
