.change-pwd-container {

  .change-pwd-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }

  .form-holder {
    width: 100%;
    margin-top: 1rem;
  }

  .btn-submit {
    margin: 1.5rem 0 1rem;
  }
}
