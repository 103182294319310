.forget-container {

  .forget-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
  }

  .forget-logo {
    width: 1.6rem;
  }

  .form-holder {
    width: 100%;
    margin-top: 1rem;
  }

  .email-code-input {
    margin: 1rem 0 .5rem;
  }

  .btn-submit {
    margin: 1.5rem 0 1rem;
  }
}
