html {
  font-size: 16px;
}

body {
  min-height: 100vh;
  background-color: rgb(250, 250, 251) !important;

  .MuiButton-root {
    .MuiButton-startIcon {
      margin-right: 3px;
    }
  }

  table {
    .MuiButton-sizeSmall {
      .MuiButton-label {
        .MuiButton-startIcon {
          margin-right: .1rem;
        }
      }
    }
  }

}

#root {
  position: relative;
  min-height: 100vh;
}

.page-content {
  padding-bottom: 5.5rem;
}

.color-danger {
  color: $danger;
}

.color-success {
  color: $success;
}
