.login-container {
   height: 100vh;

   .login-bgi {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
  }

  .login-logo {
    width: 1.6rem;
  }

  .note {
    margin-top: 1rem;
    font-style: italic;
    font-size: .85rem;
    color: #777;
  }

  .form-holder {
    width: 100%;
    margin-top: 1rem;
  }

  .btn-submit {
    margin: 1.5rem 0 1rem;
  }
}
