.account-activity-container {
  .title {
    margin-top: 1.5rem;
  }

  .account-activity-list {
    margin-top: .5rem;

    .list-empty {
      text-align: center;
      font-size: .9rem;
    }

    .list-card {
      font-size: .9rem;

      >hr {
        margin-top: 1.2rem;
        border-color: #eee;
      }
    }

    .activity-type {

    }

    .helper-text {
      margin-bottom: .3rem;
      font-size: .6rem;
      color: #777;
    }
  }



  .account-activity-pagination-container {
    margin: 1.2rem 0;
  }
}
