.job-detail-container {
  .job-detail-header {
    margin-top: 1rem;
    padding-bottom: .5rem;

    .symbol-icon-holder {
      display: flex;
      align-items: center;
    }

    .symbol-icon {
      align-items: center;
      width: 4.2rem;
      margin-right: 1.2rem;
    }

    .symbol-name {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .symbol-price {
      font-size: 1.3rem;
    }

    .unit-text {
      margin-left: .3rem;
      font-size: .6rem;
    }

    .grid-helper-text {
      font-size: .8rem;
      color: #777;
    }

    .second-line {
      margin-top: 1.4rem;
      font-size: 1rem;
    }
  }

  .chart-option {
    margin-top: 1.5rem;
  }

  .option-left {
    flex: 1;
    margin-bottom: 1rem;
  }

  .option-right {
    text-align: right;
  }

  .chart-time-control {
    min-width: 6rem;
    text-align: left;
  }

  .chart-title {
    font-size: 1rem;
  }

  .chart-helper-text {
    font-size: .8rem;
    color: #777;
  }

  .line-chart {
    margin-top: 1rem;
    padding-bottom: .6rem;
  }

  .chart-income-axis {
    color: #faa;
  }

  .job-info-header {
    margin-top: 1.5rem;

    .title {
      flex-grow: 1;
      font-size: 1rem;
    }

    .MuiButton-contained {
      margin-left: .5rem;
    }
  }

  .job-detail-grid-container {
    margin-top: .2rem;

    .job-status-0 {
      .MuiInputBase-input {
        color: $danger;
      }
    }

    .job-status-1 {
      .MuiInputBase-input {
        color: $success;
      }
    }
  }

  .job-logs-title {
    margin-top: 3rem;
    font-size: 1rem;
  }

  .job-logs-helper-text {
    font-size: .8rem;
    color: #777;
  }

  .job-detail-logs {
    margin-top: .5rem;

    .job-logs-empty {
      text-align: center;
      font-size: .9rem;
    }

    .job-detail-logs-card {
      font-size: .9rem;

      >hr {
        margin-top: 1.2rem;
        border-color: #eee;
      }
    }

    .job-logs-status-0 {
      color: $danger;
    }

    .job-logs-status-1 {
      color: $success;
    }

    .job-logs-helper-text {
      font-size: .6rem;
      color: #777;
    }

  }

  .job-logs-pagination-container {
    margin: 1.2rem 0;
  }
}
