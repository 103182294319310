.user-container {
  .user-list {
    width: 100%;
    margin-top: 1.5rem;

    .MuiListItem-gutters {
      margin-bottom: .5rem;
    }
  }

  .pwd-level-0 >input {
    color: $danger;
  }

  .pwd-level-1 >input {
    color: $warning;
  }

  .pwd-level-2 >input {
    color: $info;
  }

  .pwd-level-3 >input {
    color: $success;
  }

  .two-fa-enabled {
    color: $success;
  }

  .two-fa-disabled {
    color: $danger;
  }
}
