.header-container {
  .logo {
    width: 1.3rem;
    vertical-align: middle;
  }

  .site-note {
    flex-grow: 1;
    margin-left: .7rem;
    font-size: .8rem;
    font-style: italic;
    color: #fff;
  }

  .header-menu {
    color: #fff;
  }
}

.drawer-list {
  width: 15rem;

  .MuiListItemIcon-root {
    min-width: 36px;
  }
}
