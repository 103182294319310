@import "../Variables";

.dashboard-container {

  .dashboard-header {
    margin-top: 1rem;
    padding-bottom: 2.5rem;

    .symbol-price {
      font-size: 1.3rem;
    }

    .unit-text {
      margin-left: .3rem;
      font-size: .6rem;
    }

    .grid-helper-text {
      font-size: .6rem;
      color: #777;
    }
  }

  .chart-option {
    margin-top: 1.5rem;
  }

  .option-left {
    flex: 1;
    margin-bottom: 1rem;
  }

  .option-right {
    text-align: right;
  }

  .chart-time-control {
    min-width: 6rem;
    text-align: left;
  }

  .chart-title {
    font-size: 1rem;
  }

  .chart-helper-text {
    font-size: .8rem;
    color: #777;
  }
  .summary-helper-text {
    font-size: 1.2rem;
    color: rgb(21, 179, 68);
  }

  .line-chart {
    margin-top: 1rem;
    padding-bottom: .6rem;
  }

  .add-job-holder {
    margin-top: 1.6rem;
    text-align: center;
  }

  .dashboard-jobs-container {
    margin-top: .6rem;

    .dashboard-jobs-empty {
      text-align: center;
      font-size: .9rem;
      color: #777;

      >svg {
        font-size: 6rem;
        color: #ddd;
      }
    }

    .count-cost {
      padding-bottom: .6rem;
      font-size: .8rem;
      color: #777;

      >b {
        margin: 0 .2rem;
      }
    }

    .dashboard-jobs-card {
      font-size: .9rem;

      >hr {
        margin-top: 1.5rem;
        border-color: #eee;
      }

      .dashboard-jobs-options {
        margin-top: .4rem;
        text-align: center;

        >button {
          padding: .3rem .6rem;
          font-size: .8rem;
          line-height: .8rem;
        }
      }

      .list-helper-text {
        margin-bottom: .2rem;
        font-size: .6rem;
        color: #777;
      }

      .statics-helper-text {
        margin-bottom: .2rem;
        font-size: .6rem;
        color: #777;
      }

      .symbol-icon {
        width: 1.2rem;
        margin-right: .4rem;
        vertical-align: sub;
      }
      .statics-status {
        color: $success;
        font-size: 1rem;
      }
      .statics-status-info {
        color: $info;
        font-size: 1rem;
      }
    }
  }

  .dashboard-pagination-container {
    margin: 1.2rem 0;
  }
}
