.two-fa-container {

  .two-fa-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }

  .tips {
    width: 100%;
    color: #555;
  }

  .qrcode {
    margin: 1.6rem 0 .5rem;
  }

  .form-holder {
    width: 100%;
    margin-top: 1rem;
  }

  .btn-submit {
    margin: 1.5rem 0 1rem;
  }
}
