.safety-check-modal-paper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 30rem;
  padding: 2rem 2.5rem;
  background-color: #fff;
  border: 0;
  border-radius: 0.3rem;

  .title {
    margin-bottom: .5rem;
  }

  .btn-submit {
    margin-top: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .safety-check-modal-paper {
    width: 90%;
  }
}
