.choose-exchange-container {
  .choose-exchange-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-holder {
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    .title {
      margin: 1.5rem 0;
    }

    .btn-exchange {
      margin-bottom: 1rem;
      width: 300px;
    }
  }
}
