.add-job-container {
  .job-form-container {
    margin-top: 2rem;

    .select-type, .select-time {
      margin-top: .8rem;
    }

    .select-base, .select-coin {
      margin-top: 1.3rem;
    }

    .select-type, .select-base, .select-coin, .select-time {
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background-color: #fff;
        padding: 0 .25rem;
      }
    }

    .radios-day, .radios-week {
      margin-top: .5rem;
    }

    .month-cost {
      margin-top: 1rem;
      font-size: .8rem;
      color: #777;
    }

    .btn-submit {
      margin-top: 1.5rem;
    }
  }
}
