.something-big-container {
  .tips {
    margin-top: 2rem;

    ul {
      padding-left: 1rem;

      li {
        margin-top: .6rem;
        font-size: .8rem;
        color: #777;
      }
    }
  }

  .form-container {
    margin-top: 1rem;

    .select-type {
      margin-top: .8rem;
    }

    .select-base, .select-coin {
      margin-top: 1.3rem;
    }

    .select-type, .select-base, .select-coin {
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background-color: #fff;
        padding: 0 .25rem;
      }
    }

    .btn-submit {
      margin-top: 1.5rem;
    }
  }
}
