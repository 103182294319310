.error-container {
  text-align: center;

  @keyframes shake {
    0%, 70% {
      transform: translate3d(-1px, 0, 0) rotate(-145deg);
    }

    71%, 99% {
      transform: translate3d(-1px, 0, 0) rotate(-145deg);
    }

    74%, 92% {
      transform: translate3d(2px, 0, 0) rotate(-145deg);
    }

    77%, 83%, 89% {
      transform: translate3d(-4px, 0, 0) rotate(-145deg);
    }

    80%, 86% {
      transform: translate3d(4px, 0, 0) rotate(-145deg);
    }

    100% {
      transform: translate3d(0, 0, 0) rotate(-145deg);
    }
  }

  .gray-logo {
    margin-top: 3rem;
    width: 3rem;
    filter: grayscale(100%);
    opacity: .35;
    animation: shake 2s ease-in-out infinite both;
  }

  .code {
    margin-top: 1rem;
    font-size: 6rem;
    color: #ccc;
  }

  .text {
    margin-bottom: 3rem;
    font-size: 2rem;
    color: #ccc;
  }
}
